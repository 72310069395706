const isAvailable = type => {
  try {
    const storage = window[type]
    const test = '__TEST__'
    storage.setItem(test, test)
    storage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const isLocalStorageAvailable = isAvailable('localStorage')
let accessToken = null
let refreshToken = null

if (isLocalStorageAvailable) {
  accessToken = JSON.parse(localStorage.getItem('access_token'))
  refreshToken = JSON.parse(localStorage.getItem('refresh_token'))
}

export const get = key => {
  if (isLocalStorageAvailable) {
    return JSON.parse(localStorage.getItem(key))
  }
  return null
}

export const set = (key, data) => {
  if (isLocalStorageAvailable) {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export const getAccessTokenFromLocalStorage = () =>
  window.localStorage.getItem('access_token')
export const getAccessToken = () => accessToken
export const setAccessToken = token => {
  accessToken = token
  set('access_token', token)
}

export const getMobileAccessTokenFromLocalStorage = () =>
  window.localStorage.getItem('mobile_access_token')

export const getRefreshToken = () => refreshToken
export const getEmail = () => get("logged_email");
export const setRefreshToken = token => {
  refreshToken = token
  set('refresh_token', token)
}
export const getLoggedInUserID = () => get("logged_userid");
export const setLoggedInUserID = (userID) => {  
  set("logged_userid", userID);
};
export const setLoggedInEmail = email => {
  set("logged_email", email);
}
export const remove = () => {
  if (isLocalStorageAvailable) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem("logged_userid");
    localStorage.removeItem("logged_email");
  }
}

export const isUserLoggedIn = () => Boolean(accessToken);
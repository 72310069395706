import {
  ReferralColumnName,
  Row,
  SendReminderButton,
  StatusIndicator,
  ReferralColumnValue,
  TableRow,
  TableCol,
} from "src/app/components/styled";
import colors from "src/app/colors";

const getStatusColorAndLabel = (status) => {
  switch (status) {
    case "invitation_sent":
      return {
        color: colors.orange,
        label: "Invitation Sent",
      };
    case "onboarding":
      return {
        color: colors.brightYellow,
        label: "Onboarding",
      };
    case "qualified":
      return {
        color: colors.green,
        label: "Qualified",
      };
    case "not_qualified":
      return {
        color: colors.red,
        label: "Not Qualified",
      };
    default:
      return {
        color: colors.orange,
        label: "Invitation Sent",
      };
  }
};

const ReferralTable = ({ currentRefferals, onSendReminder }) => (
  <>
    <TableRow noBorder isHeader>
      <TableCol>
        <ReferralColumnName isHeader>Referral Name</ReferralColumnName>
      </TableCol>
      <TableCol>
        <ReferralColumnName isHeader>Email Address</ReferralColumnName>
      </TableCol>
      <TableCol>
        <ReferralColumnName isHeader>Status</ReferralColumnName>
      </TableCol>
      <TableCol></TableCol>
    </TableRow>

    {currentRefferals.map((r, i) => (
      <ReferralInfoRow
        key={i}
        referral={r}
        index={i}
        total={currentRefferals.length}
        onSendReminder={onSendReminder}
      />
    ))}
  </>
);

const ReferralInfoRow = ({ referral, index, total, onSendReminder }) => {
  const status = getStatusColorAndLabel(referral.status);
  return (
    <>
      <TableRow>
        <TableCol>
          <ReferralColumnName>Referral Name</ReferralColumnName>
          <ReferralColumnValue>{referral.referral_name}</ReferralColumnValue>
        </TableCol>

        <TableCol>
          <ReferralColumnName>Email Address</ReferralColumnName>
          <ReferralColumnValue>{referral.referral_email}</ReferralColumnValue>
        </TableCol>

        <TableCol>
          <ReferralColumnName>Status</ReferralColumnName>
          <ReferralColumnValue>
            <Row alignItems="center">
              <StatusIndicator color={status.color} />
              {status.label}
            </Row>
          </ReferralColumnValue>
        </TableCol>

        <TableCol noOverflow justifyContent="center">
          <SendReminderButton
            onClick={() => onSendReminder(referral.id)}
            color={colors.gray}
          >
            Send a reminder
          </SendReminderButton>
        </TableCol>
      </TableRow>
    </>
  );
};

export default ReferralTable;

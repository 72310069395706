import * as storage from "../utils/storage";
import request from "./request";

const API_HOST = process.env.REACT_APP_BASE_API_URL;

export const api = async (endpoint, options, restricted) => {
  const url = `${API_HOST}${endpoint}`;

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  if (restricted) {
    headers.append("Authorization", `Bearer ${storage.getAccessToken()}`);
  }

  try {
    return await request(url, { ...options, headers });
  } catch (err) {

    if (err.code === 16 || err.message === "token is not valid") {
      storage.remove();
      window.location.replace("/login");
    }

    if (err.code !== 16 || err.message !== "token has expired") {
      return Promise.reject(err);
    }

    try {
      const { access_token: accessToken } = await request(
        `${API_HOST}/users/token/refresh`,
        {
          method: "POST",
          body: JSON.stringify({ token: storage.getRefreshToken() }),
        }
      );

      storage.setAccessToken(accessToken);
      headers.set("Authorization", `Bearer ${accessToken}`);

      return request(url, { ...options, headers });
    } catch (err) {
      // Login component logs user out and resets app
      window.location.replace("/login");
    }
  }
};

/* prettier-ignore */
api.post = (endpoint, restricted) => body => api(endpoint, { method: 'POST', body: JSON.stringify(body) }, restricted)

export const getLoginPasscode = api.post("/users/signin/getpasscode", false);
export const loginWithPasscode = api.post("/users/signin/withpasscode", false);
export const getReferrals = api.post("/referrals/list", true);
export const submitReferrals = api.post("/referrals/submit", true);
export const submitMessage = api.post("/referrals/submit_message", true);
export const getUserPrimaryAccount = api.post("/referrals/get_user_primary_account", true);

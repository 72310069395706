const colors = {
  lightBlue: "#F1F3F9",
  royalBlue: "#2830A6",
  darkBlue: "#25245A",
  gray: "#5F6076",
  white: "#FFFFFF",
  lightGreen: "#42DA80",
  blue: "#25245D",
  green: "#6BC686",
  yellow: "#EA900A",
  brightYellow: "#FFF53D",
  red: "#DD3B10",
  navy: "#252557",
  royal: "#2830A6",
  purple: "#635AAD",
  lightGray: "#D9D9D9",
  orange: "#FC780B",
  progressBackground: "#d1d1d1"
};

export default colors;

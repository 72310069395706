import { useState } from "react";
import {
  Box,
  Text,
  LoginContainer,
  Row,
  Button,
  InputTextField,
  FooterContainer,
  TitleText,
  LabelText,
  MenuContainer,
  FreeEnergyLogoContainer,
  LeftFooterHomeImage,
  RightFooterHomeImage,
  FooterLogoContainer,
  LoginButtonContainer,
  ThinkEnergyLogoContainer,
  HyperLink,
} from "src/app/components/styled";
import colors from "src/app/colors";
import FreeEnergyLogo from "src/app/assets/icons/free-energy-club-logo.svg";
import ThinkLogo from "src/app/assets/icons/think-logo.svg";
import LeftHome from "src/app/assets/icons/home_1.svg";
import RightHome from "src/app/assets/icons/home_2.svg";
import { getLoginPasscode, loginWithPasscode } from "src/api";
import * as storage from "src/utils/storage";
import toast, { Toaster } from "react-hot-toast";

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [passCode, setAccessCode] = useState("");
  const [codeRequestSent, setCodeRequestSent] = useState(false);

  const notifyError = (message) => toast.error(message);
  const notifySucess = (message) => toast.success(message);

  const onRequestLoginCode = () => {
    const sanitizedEmail = email.trim();
    getLoginPasscode({ email: sanitizedEmail })
      .then((res) => {
        if (res.success) {
          setCodeRequestSent(true);
          notifySucess(
            "Please check your e-mail and enter the access code below"
          );
        } else {
          notifyError("You must be an existing Think Energy customer to login");
        }
      })
      .catch((e) => notifyError(e.message));
  };
  const onSubmitAccessCode = () => {
    loginWithPasscode({ email, passCode })
      .then((res) => {
        setCodeRequestSent(false);
        storage.setAccessToken(res.access_token);
        storage.setRefreshToken(res.refresh_token);
        storage.setLoggedInUserID(res.userID);
        storage.setLoggedInEmail(email);
        setIsLoggedIn(true);
      })
      .catch((e) => notifyError("Invalid access code"));
  };

  return (
    <>
      <Toaster />
      <Box m="32px">
        <HyperLink
          href="https://thinkenergy.com/"
          target="_blank"
          rel="noreferrer"
        >
          <ThinkEnergyLogoContainer src={ThinkLogo} alt="think energy logo" />
        </HyperLink>
        <MenuContainer>
          <Button
            bg={colors.white}
            color={colors.gray}
            onClick={() => onRequestLoginCode()}
          >
            Clean Electricity
          </Button>
          <Button
            bg={colors.white}
            color={colors.gray}
            onClick={() => onRequestLoginCode()}
          >
            Community Solar
          </Button>
          <Button
            bg={colors.white}
            color={colors.gray}
            onClick={() => onRequestLoginCode()}
          >
            Community Solar
          </Button>
          <Button
            bg={colors.white}
            color={colors.gray}
            onClick={() => onRequestLoginCode()}
          >
            Refer & Earn
          </Button>
          <Button
            bg={colors.white}
            color={colors.gray}
            onClick={() => onRequestLoginCode()}
          >
            About
          </Button>
          <Button
            bc={colors.lightGray}
            color={colors.gray}
            onClick={() => onRequestLoginCode()}
          >
            Existing Customer
          </Button>
          <Button
            bg={colors.lightGreen}
            color={colors.darkBlue}
            onClick={() => onRequestLoginCode()}
          >
            Sign up
          </Button>
        </MenuContainer>
        <Row justifyContent="center" m={10}>
          <FreeEnergyLogoContainer
            src={FreeEnergyLogo}
            alt="free energy logo"
          />
        </Row>
        <Row justifyContent="center">
          <LoginContainer>
            <TitleText color={colors.darkBlue}>
              Log into your Free Energy Club portal
            </TitleText>
            {!codeRequestSent && (
              <Row justifyContent="center" padding={"25px 0px"} width="100%">
                <InputTextField
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Row>
            )}
            {!codeRequestSent && (
              <LoginButtonContainer>
                <Box justifyContent="center">
                  <HyperLink
                    href="https://thinkenergy.com/fec-terms/"
                    target="_blank"
                    rel="noreferrer"
                    color={colors.gray}
                  >
                    <LabelText color={colors.gray} mt={10} mr={24}>
                      By clicking Continue, you are agreeing to the Free Energy
                      Club Terms & Conditions.
                    </LabelText>
                  </HyperLink>
                </Box>
                <Box justifyContent="center" mt="10px">
                  <Button
                    bg={colors.lightGreen}
                    color={colors.darkBlue}
                    onClick={() => onRequestLoginCode()}
                  >
                    Continue
                  </Button>
                </Box>
              </LoginButtonContainer>
            )}
            {codeRequestSent && (
              <>
                <Text
                  color={colors.gray}
                  fontWeight={400}
                  fontSize={"12px"}
                  padding={"10px 0px"}
                >
                  Please check your inbox and enter the code below. An email was
                  sent to {email}.
                </Text>
                <Row
                  justifyContent="center"
                  padding={"15px 0px 25px"}
                  width="100%"
                >
                  <InputTextField
                    placeholder="access code"
                    value={passCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                    mb={0}
                    p={0}
                  />
                </Row>
              </>
            )}
            {codeRequestSent && (
              <Row justifyContent="center" m={"0"}>
                <Button
                  bg={colors.lightGreen}
                  color={colors.darkBlue}
                  onClick={() => onRequestLoginCode()}
                >
                  Resend
                </Button>
                <Button
                  style={{ marginRight: "0px" }}
                  bg={colors.lightGreen}
                  color={colors.darkBlue}
                  onClick={() => onSubmitAccessCode()}
                >
                  Submit
                </Button>
              </Row>
            )}
          </LoginContainer>
        </Row>
        <Row justifyContent="center" mt={20}>
          <Button
            bg={colors.white}
            color={colors.gray}
            onClick={() => window.open("https://www.thinkenergy.com")}
          >
            Not a customer?
          </Button>
        </Row>
        <Row justifyContent="center">
          <Button
            bc={colors.lightGreen}
            color={colors.gray}
            onClick={() => window.open("https://www.thinkenergy.com")}
          >
            Become a Customer
          </Button>
        </Row>
      </Box>
      <FooterLogoContainer>
        <LeftFooterHomeImage src={LeftHome} alt="" />
        <RightFooterHomeImage src={RightHome} alt="" />
      </FooterLogoContainer>

      <FooterContainer>
        <Row>
          <Text color={colors.white} fontWeight={500} fontSize={"10px"} ml={40}>
            Copyright 2022 • Think Energy LLC |
            <HyperLink
              href="https://thinkenergy.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </HyperLink>{" "}
            •{" "}
            <HyperLink
              href="https://thinkenergy.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </HyperLink>
          </Text>
        </Row>
        <Row>
          <Text
            color={colors.white}
            fontWeight={500}
            fontSize={"16px"}
            mr={10}
            ml={40}
          >
            Questions?{" "}
          </Text>
          <Text
            color={colors.lightGreen}
            fontWeight={500}
            fontSize={"16px"}
            mr={40}
          >
            1-888-923-3633
          </Text>
        </Row>
      </FooterContainer>
    </>
  );
};

export default Login;

import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Row,
  Button,
  InputTextField,
  InputCheckbox,
  FooterContainerSticky,
  HeaderDash,
  AccountSubHeader,
  SubHeaderDash,
  SubHeader2,
  SubHeader6,
  AccountStatusContainer,
  LoggedInMenuContainer,
  ThinkEnergyLogo,
  ThinkEnergyLogoContainer2,
  YouAreLoggedInContainer,
  MainContainer,
  HowItWorksContainer,
  YourReferralContainer,
  PageContainer,
  AccountStatusText,
  AccountStatusAction,
  HowItWorksItemsContainer,
  HowItWorksHeaderContainer,
  GetFriendsText,
  ForMoreInformationButton,
  LoginButton,
  HyperLink,
  ReferAndEarnRow,
  ReferAndEarnRowItem,
  SendEmailContainer,
  AccountStatusIndicator,
  ProgressBackground,
  PorgressIndicator,
} from "src/app/components/styled";
import toast, { Toaster } from "react-hot-toast";
import colors from "src/app/colors";
import Logo from "src/app/assets/icons/logo.png";
import FriendsIcon from "src/app/assets/icons/friends.png";
import ShareIcon from "src/app/assets/icons/share.png";

import {
  getReferrals,
  submitReferrals,
  submitMessage,
  getUserPrimaryAccount,
} from "src/api";
import * as storage from "src/utils/storage";
import ReferralTable from "./ReferralInfo";

const ACCOUNT_SATUS = {
  pending: "pending",
  active: "active",
  inactive: "inactive",
};

const REFERRAL_STATUS = {
  exists: "Already Exists",
  sent: "invitation_sent",
};

const mapAccountStatus = (accountStatus) => {
  if (accountStatus === ACCOUNT_SATUS.active) {
    return {
      status: "Active",
      progress: 100,
      action: "",
      progressColor: colors.lightGreen,
    };
  } else if (accountStatus === ACCOUNT_SATUS.inactive) {
    return {
      status: "Inactive",
      progress: 0,
      action: "",
      progressColor: colors.progressBackground,
    };
  }

  return {
    status: "Pending",
    progress: 50,
    action: "(No Action Required)",
    progressColor: colors.orange,
  };
};

const YourReferralAndSavings = ({ setIsLoggedIn }) => {
  const [currentRefferals, setCurrentReferrals] = useState([]);
  const [invitees, setInvitees] = useState(
    [...Array(5)].map(() => {
      return { referral_name: "", referral_email: "", eligible: true };
    })
  );

  const [accountStatus, setAccountStatus] = useState(
    mapAccountStatus(ACCOUNT_SATUS.pending)
  );

  useEffect(() => {
    getReferrals({ limit: 100 }).then((res) => {
      setCurrentReferrals(res.referrals);
    });

    const user_id = storage.getLoggedInUserID();
    getUserPrimaryAccount({ user_id }).then(
      (res) =>
        res &&
        res.details &&
        res.details.status &&
        setAccountStatus(mapAccountStatus(res.details.status))
    );
  }, []);

  const notifyError = (message) => toast.error(message);
  const notifySucess = (message) => toast.success(message);
  const loggedInEmail = storage.getEmail();

  const onSubmitReferrals = () => {
    const user_id = storage.getLoggedInUserID();
    const referrals = invitees
      .map((referral) => ({
        ...referral,
        referral_email: referral.referral_email.trim(),
        referral_name: referral.referral_name.trim(),
      }))
      .filter((i) => i.referral_email !== "" && i.referral_name !== "");

    if (referrals.length === 0) {
      notifyError("Please fill up at least one referral information");
      return;
    }

    submitReferrals({ user_id, referrals })
      .then((res) => {
        if (res.result) {
          if (res.referrals) {
            const uniqueReferrals = res.referrals.filter((referral) => {return referral.status !== REFERRAL_STATUS.exists});
            setCurrentReferrals([...currentRefferals, ...uniqueReferrals]);
          }
          setInvitees(
            [...Array(5)].map(() => {
              return { referral_name: "", referral_email: "" };
            })
          );
          notifySucess("Referral Submitted!");
        }
      })
      .catch((e) => {
        notifyError(e.message);
      });
  };

  const onSendReminder = (id) => {
    const user_id = storage.getLoggedInUserID();
    submitMessage({ user_id, id, message_type: "reminder" })
      .then((res) => {
        if (res.result) {
          notifySucess("Reminder sent!");
        }
      })
      .catch((e) => {
        notifyError("Problem occurred while sending reminder!");
      });
  };

  const onTextFieldChange = (handler, values, index, propName) => (e) => {
    const newValue = { ...values[index] };
    newValue[propName] = e.target.value;
    const newValues = [...values];
    newValues[index] = newValue;
    handler(newValues);
  };

  const onLogout = () => {
    storage.remove();
    setIsLoggedIn(false);
  };

  return (
    <>
      <Toaster />
      <LoggedInMenuContainer>
        <LoginButton bg={colors.white} color={colors.gray}>
          <YouAreLoggedInContainer>
            You are logged in as{" "}
            <span style={{ color: colors.darkBlue }}>{loggedInEmail}</span>
          </YouAreLoggedInContainer>
        </LoginButton>
        <LoginButton
          bg={colors.lightGreen}
          color={colors.darkBlue}
          onClick={() => onLogout()}
        >
          Logout
        </LoginButton>
      </LoggedInMenuContainer>
      <PageContainer>
        <ThinkEnergyLogoContainer2>
          <HyperLink
            href="https://thinkenergy.com/"
            target="_blank"
            rel="noreferrer"
          >
            <ThinkEnergyLogo src={Logo} alt="" />
          </HyperLink>
        </ThinkEnergyLogoContainer2>
        <Box textAlign="center">
          <HeaderDash>Your referral & savings overview</HeaderDash>
        </Box>
        <MainContainer>
          <AccountStatusContainer>
            <AccountSubHeader>Your Account Status</AccountSubHeader>
            <AccountStatusIndicator>
              <ProgressBackground />
              {accountStatus.progress && (
                <PorgressIndicator
                  progress={accountStatus.progress}
                  color={accountStatus.progressColor}
                />
              )}
            </AccountStatusIndicator>
            <AccountStatusText>
              {accountStatus.status}
              <AccountStatusAction>{accountStatus.action}</AccountStatusAction>
            </AccountStatusText>
          </AccountStatusContainer>

          <HowItWorksContainer>
            <HowItWorksHeaderContainer>
              <SubHeaderDash>How it works</SubHeaderDash>
              <GetFriendsText>
                Get a few friends and neighbors to choose Think Energy.
              </GetFriendsText>
            </HowItWorksHeaderContainer>
            <HowItWorksItemsContainer>
              <Box>
                <SubHeader6>Refer 3 friends and get a</SubHeader6>
                <SubHeader6>monthly rebate equal to</SubHeader6>
                <SubHeader2>10%</SubHeader2>
                <SubHeader6 color={colors.gray}>
                  of your Think Energy
                </SubHeader6>
                <SubHeader6 color={colors.gray}>
                  electricity supply charges
                </SubHeader6>
              </Box>

              <Box>
                <SubHeader6>Refer 10 friends and get a</SubHeader6>
                <SubHeader6>monthly rebate equal to</SubHeader6>
                <SubHeader2>40%</SubHeader2>
                <SubHeader6 color={colors.gray}>
                  of your Think Energy
                </SubHeader6>
                <SubHeader6 color={colors.gray}>
                  electricity supply charges
                </SubHeader6>
              </Box>

              <Box>
                <SubHeader6>Refer 20 friends and get a</SubHeader6>
                <SubHeader6>monthly rebate equal to</SubHeader6>
                <SubHeader2>100%</SubHeader2>
                <SubHeader6 color={colors.gray}>
                  of your Think Energy
                </SubHeader6>
                <SubHeader6 color={colors.gray}>
                  electricity supply charges
                </SubHeader6>
              </Box>
            </HowItWorksItemsContainer>
            <Row mt={4} mb="15px" justifyContent="center">
              <ForMoreInformationButton
                color={colors.gray}
                style={{ margin: "0px" }}
                onClick={() =>
                  window.open("https://thinkenergy.com/fec-terms/")
                }
              >
                {" "}
                For more information, review the Free Energy Club Terms &
                Conditions
              </ForMoreInformationButton>
            </Row>
          </HowItWorksContainer>
        </MainContainer>
        <YourReferralContainer>
          <img src={FriendsIcon} width="55px" height="55px" alt="" />
          <SubHeaderDash>Your Referrals</SubHeaderDash>
          <ReferralTable
            currentRefferals={currentRefferals}
            // currentRefferals={[...currentRefferals, ...currentRefferals, ...currentRefferals, ...currentRefferals, ...currentRefferals, ...currentRefferals, ...currentRefferals]}
            onSendReminder={onSendReminder}
          />
        </YourReferralContainer>
        <YourReferralContainer mt={3}>
          <img src={ShareIcon} width="36px" height="32px" alt="" />
          <Row mt={"15px"}>
            <Text
              color={colors.royalBlue}
              fontWeight={500}
              fontSize={"30px"}
              mr={10}
            >
              Refer and earn
              <Text
                color={colors.gray}
                fontWeight={400}
                fontSize={"12px"}
                mt={20}
                pt="2px"
              >
                Send a simple message for up to 5 friends at a time.
              </Text>
            </Text>
          </Row>
          {invitees.map((invitee, i) => (
            <Row mt={"30px"} alignItems="center" key={i}>
              <Text
                color={colors.gray}
                fontWeight={700}
                fontSize={"21px"}
                mr={"15px"}
              >
                {" "}
                {i + 1}
              </Text>
              <ReferAndEarnRow>
                <ReferAndEarnRowItem mr={"20px"}>
                  <InputTextField
                    placeholder="Full name"
                    value={invitee.referral_name}
                    onChange={onTextFieldChange(
                      setInvitees,
                      invitees,
                      i,
                      "referral_name"
                    )}
                  />
                </ReferAndEarnRowItem>
                <ReferAndEarnRowItem>
                  <InputTextField
                    placeholder="Email address"
                    value={invitee.referral_email}
                    onChange={onTextFieldChange(
                      setInvitees,
                      invitees,
                      i,
                      "referral_email"
                    )}
                  />
                </ReferAndEarnRowItem>
              </ReferAndEarnRow>
            </Row>
          ))}

          <SendEmailContainer>
            <Row alignItems="center" style={{ display: "none" }}>
              <InputCheckbox />
              <Text
                color={colors.gray}
                fontWeight={500}
                fontSize={"21px"}
                ml={"8px"}
              >
                Send me a copy of this email
              </Text>
            </Row>

            <Button
              bg={colors.lightGreen}
              color={colors.darkBlue}
              onClick={() => onSubmitReferrals()}
            >
              Send email
            </Button>
          </SendEmailContainer>
        </YourReferralContainer>
      </PageContainer>
      <FooterContainerSticky>
        <Row>
          <Text color={colors.white} fontWeight={500} fontSize={"10px"} ml={40}>
            Copyright 2022 • Think Energy LLC |{" "}
            <HyperLink
              href="https://thinkenergy.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </HyperLink>{" "}
            •
            <HyperLink
              href="https://thinkenergy.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </HyperLink>
          </Text>
        </Row>
        <Row>
          <Text
            color={colors.white}
            fontWeight={500}
            fontSize={"16px"}
            mr={10}
            ml={40}
          >
            Questions?{" "}
          </Text>
          <Text
            color={colors.lightGreen}
            fontWeight={500}
            fontSize={"16px"}
            mr={40}
          >
            1-888-923-3633
          </Text>
        </Row>
      </FooterContainerSticky>
    </>
  );
};
export default YourReferralAndSavings;

import { useState, useEffect } from "react";
import queryString from 'query-string'
import YourReferralAndSavings from "./containers/YourReferralAndSavings";
import Login from "./containers/Login";
import Maintenance from "./containers/Maintenance";

import { isUserLoggedIn } from "../utils/storage";
import "@fontsource/dm-sans";
import "@fontsource/barlow";
import Footer from "./components/Footer";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn);

  // use this to get the query string parameters to set the access token and refresh token
  const location = window.location
  const { hl } = queryString.parse(location.search)
  const headLess = hl

  useEffect(() => { }, [isLoggedIn]);

  return (
    <>
      {process.env.REACT_APP_MAINTENANCE_MODE !== 'true' && (
        <>
          {
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <YourReferralAndSavings setIsLoggedIn={setIsLoggedIn} />
            )}
        </>
      )}
      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' && (
        <>
          <Maintenance headLess={hl} />
          {!headLess && <Footer />}
        </>
      )}
    </>
  );
};

export default App;
